import React from 'react';
import {Slider} from "../Home/Slider"
import Cards from './Cards';
import "../Home/Home.css"
import Details from './Details';
import Join from "../Home/Join"
import Last from "../Home/LastSection"
const Home = () => {
  return <div className='home-section'>
       <Slider/>
       

       <div className='cardss'>
                   <Cards/>




       </div>

       <div>
           <Details/>
       </div>

       <div>
           <Join/>
       </div>
       <div>
          <Last/>
       </div>





  </div>;
};

export default Home;

       




