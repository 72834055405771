import React from 'react';
import img1 from "../../Assests/tempura web banner'.jpg"
import img2 from "../../Assests/Untitled-2 (1).jpg"
import img3 from "../../Assests/Untitled-3.jpg"
import { Carousel } from 'react-bootstrap';


export const Slider = () => {
  return <div>
      <Carousel fade>
  <Carousel.Item interval={2000}>
    <img
      className="d-block w-100"
      src={img1}
      alt="First slide"
    />
    <Carousel.Caption>
    
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={2000}>
    <img
      className="d-block w-100"
      src={img2}
      alt="Second slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={3000}>
    <img
      className="d-block w-100"
      src={img3}
      alt="Third slide"
    />
    <Carousel.Caption>
    
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>





  </div>;
};
