import React from 'react';
import PageBanner from '../PageBanner';
import banner from "../../Assests/10001.jpg"
import card1 from "../../Assests/NUGGETS.jpg"
import card2  from  "../../Assests/CHICKEN SAMOSA.jpg"
import card3 from "../../Assests/BEEF SAMOSA.jpg"


const OurFood = () => {
  return (

<div className="service-page">
    <section id="Banner"><PageBanner banner={banner} /></section>
    <section id="content">
        <div className="container-fluid">
      
            <div className="row">
                <div className="col-md-12 text-center my-3">
                    <h1 Style={'color:#971B1E'} >Our Food</h1>
                </div>
            </div>
            <div className='col-12'>
            <div className="row">
                <div className='col-md-4 col-sm-4 col-xs-4 col-lg-4  text-justify my-3'>
                <img  className='img-fluid' Style={'border-radius: 15px' } src={card1} alt="foods"></img>
                    <h4 className='text-center my-3' Style={'color:white'}>Appetizers</h4>
                    <p Style={' color: darkgray;'}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec consequat mi. Duis pulvinar laoreet lacus, in tempor libero dignissim a. Morbi mattis orci magna, vitae rhoncus felis dapibus quis.
                    </p>

                </div>
                <div className='col-md-4 col-sm-4 col-xs-4 col-lg-4  text-justify my-3'>
                  
                  <img  className='img-fluid' Style={'border-radius: 15px' } src={card2} alt="foodss"></img>
                  <h4  className='text-center my-3'  Style={'color:white'}>Kababs & Grill</h4>
                  <p Style={' color: darkgray;'}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec consequat mi. Duis pulvinar laoreet lacus, in tempor libero dignissim a. Morbi mattis orci magna, vitae rhoncus felis dapibus quis.
                    </p>

                </div>
                <div className='col-md-4 col-sm-4 col-xs-4 col-lg-4  text-justify my-3'>

                <img  className='img-fluid' Style={'border-radius: 15px' } src={card3} alt="ff"></img>
              <h4  className='text-center my-3'  Style={'color:white'}>Ready To Eat Meals</h4>  
              <p Style={' color: darkgray;'}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec consequat mi. Duis pulvinar laoreet lacus, in tempor libero dignissim a. Morbi mattis orci magna, vitae rhoncus felis dapibus quis.
                    </p>

                </div>
                </div>
            </div>
        </div>
    </section>
</div>   
  )
};

export default OurFood;



