import React from 'react'
import { Navbar,Nav} from 'react-bootstrap'
import lasty from "../../Assests/Reception-02.png"
import {Link} from "react-router-dom"
import "../Navbar/Header.css"
const Header = () => {
  return (
 
     <>
     <Navbar  expand="lg" className='navbar' Style={'background-color: black '}>
  <Navbar.Brand  ><img src={lasty} alt="fdsf" Style={"width:170px; "}/></Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" Style={'background-color: white '}/>
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto" Style={'display:flex ;width:100% ; justify-content:space-evenly ; font-weight:900 ;background-color: black '}>
    <Link to="/" className="nav-link"  Style={'color:red'}>Home</Link>
    <Link to="/story" className="nav-link">Our Story</Link>
    <Link to="/Food" className="nav-link">Our Food</Link>
    <Link className="nav-link" to="/contact">Contact Us</Link>
    {/* <Link to="/Storelocator" className="nav-link">Our Stores</Link> */}
      
    </Nav>
    
  </Navbar.Collapse>
</Navbar>
     </>
  )
}

export default Header