import React from 'react';
import banner from "../../Assests/10001.jpg"
import PageBanner from '../PageBanner';
import img1 from "../../Assests/asrarwname-236x300.png"

const Our = () => {
  return(
    
    <div className="service-page">
    <section id="Banner"><PageBanner banner={banner} /></section>
    <section id="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 text-center my-3">
                    <h1 Style={'color:#971B1E'}>Our Story</h1>
                </div>
            </div>
            <div className="row">
                <div className='col-md-6 col-sm-6 col-xs-6 col-lg-6 text-center py-3'>
                  <div className='img-fluid'>
                    <img className='detailss-img' src={img1} alt="fff"></img>
                  </div>

                </div>   
                <div className='col-md-6 col-sm-6 col-xs-6 col-lg-6'>

                  <h2 className='our-story'Style={'color:white'}>Welcome to Tempura</h2>
                  <p className='our-para'Style={' color: darkgray;'}> 
                  Tempura means authentic Pakistani food with a Japanese fusion, marinated and cooked with the best spices Pakistan has to offer, right here in Houston.<br/>

In 2009, with God’s help, I opened our first location in the north side of Houston with a commitment to use the same passion and recipes of my mother. Since then, we have grown to three locations and have formed semi-exclusive partnerships with hotels such as the JW Marriot, Hilton Americas, and others for catering.<br/>

At Tempura we promise you will taste the same spices, smell the same aromas, and feel the same tingle as I did with my mother’s cooking, and her mother before her. At Tempura, you’ll taste Pakistani tradition! <br/>
                  </p>

                </div>
            </div>
        </div>
    </section>
</div>   
      
  )
};

export default Our;

    

