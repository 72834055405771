import React from 'react';
import "../Footer/Footer.css"
import {BsFacebook} from "react-icons/bs"
import {AiFillInstagram} from "react-icons/ai"
const Footer = () => {
  return <div className='footer'>

      <div  className='footer-section'>
       <h6>© 2022 MindWhiz. All Rights Reserved</h6>
       <span className='fb'><BsFacebook/></span>
       &nbsp;&nbsp;&nbsp;
       <span className='in'><AiFillInstagram/></span>

       </div>







  </div>;
};

export default Footer;








