import React from 'react';
import banner from "../../Assests/10001.jpg"
import PageBanner from '../PageBanner';


const Storelocatior = () => {
  return(
    
    <div className="service-page">
    <section id="Banner"><PageBanner banner={banner} /></section>
    <section id="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 text-center my-3">
                    <h1>Our Stores</h1>
                </div>
            </div>
            <div className="row">
                <div className='col-md-6 col-sm-6 col-xs-6 col-lg-6 text-center py-3'>
                <div  className='stores-heading'>
                    <h1 className='Store-h1'>FM 1960</h1>
                   <hr className='store-divider'/>
                   <h5 className='Store-h5'>10640 Cypress Creek Pkwy, Houston, TX 77070</h5>
                   <h6 className='store-h6'>Tel: (281)890-6969</h6>
                   <div className='store-button'>
                       <a href='https://www.google.com/maps/place/10640+Cypress+Creek+Pkwy,+Houston,+TX+77070,+USA/@29.9364278,-95.5834655,17z/data=!3m1!4b1!4m5!3m4!1s0x8640d1ed67c95b17:0x54163fe3ab99032e!8m2!3d29.9364278!4d-95.5812768' className='store-get'>Get Direction</a>
                       <button className='store-order'>Order Online </button>
                   </div>


                </div>
                  

                </div>
                <div className='col-md-6 col-sm-6 col-xs-6 col-lg-6 text-center py-3'>
                <div  className='stores-heading'>
                    <h1 className='Store-h1'>Hillcroft</h1>
                    <hr className='store-divider'/>
                   <h5 className='Store-h5'>5901 Hillcroft St B6 , Houston, TX 77036</h5>
                   <h6 className='store-h6'>Tel: (832)740-4255</h6>
                   <div className='store-button'>
                       <a href='https://www.google.com/maps/place/5901+Hillcroft+St+b6,+Houston,+TX+77036,+USA/data=!4m2!3m1!1s0x8640c24a887aafeb:0x3ad29128e5961da2?sa=X&ved=2ahUKEwj5qriq-eX1AhUD_rsIHb9gD-YQ8gF6BAgOEAE' className='store-get'>Get Direction</a>
                       <button className='store-order'>Order Online </button>
                   </div>
                   </div>

               

                </div>

                    <div className='col-md-6 col-sm-6 col-xs-6 col-lg-6 text-center py-3'>
                    <div  className='stores -heading'>
                    <h1 className='Store-h1'>Antoine</h1>
                    <hr className='store-divider'/>
                   <h5 className='Store-h5'>12228 Antoine Dr. Ste B, Houston, TX 77066</h5>
                   <h6 className='store-h6'>Tel: (832)446-3443</h6>
                   <div className='store-button'>
                       <a  href="https://www.google.com/maps/place/10640+Cypress+Creek+Pkwy,+Houston,+TX+77070,+USA/@29.9364278,-95.5834655,17z/data=!3m1!4b1!4m5!3m4!1s0x8640d1ed67c95b17:0x54163fe3ab99032e!8m2!3d29.9364278!4d-95.5812768"  className='store-get'>Get Direction</a>
                       <button className='store-order'>Order Online </button>
                       
                   </div>
                   </div>

                    </div>

                
            </div>
        </div>
    </section>
</div>   
      
  )
};

export default Storelocatior;

    

