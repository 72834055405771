import React from 'react';
import card1 from "../../Assests/NUGGETS.jpg"
import card2  from  "../../Assests/CHICKEN SAMOSA.jpg"
import card3 from "../../Assests/BEEF SAMOSA.jpg"
import card4 from "../../Assests/VEGETABLE SAMOSA.jpg"
import card5 from "../../Assests/SPRING ROLLS.jpg"

const Cards = () => {
  return <div>

<div className='container-fluid'>
<h3 className='card-headings'>Discover Our Food</h3>
  <div className='col-12'>
    <div className='row'>
    <div className='col-md-4 col-sm-4 col-xs-4 col-lg-4  text-center my-3'>
    <h4 className='card-heading'>Chicken Nuggets </h4>
    <img  className='img-fluid' Style={'border-radius: 15px' } src={card1} alt="card1"></img>

      </div>
      <div className='col-md-4 col-sm-4 col-xs-4 col-lg-4  text-center my-3'>
      <h4 className='card-heading'>Chicken Samosa </h4>
      <img  className='img-fluid' Style={'border-radius: 15px' } src={card2} alt="card2"></img>
      
      </div>
      <div className='col-md-4 col-sm-4 col-xs-4 col-lg-4  text-center my-3'>
      <h4 className='card-heading'>Beef Samosa </h4>
      <img  className='img-fluid' Style={'border-radius: 15px' } src={card3} alt="card3"></img>
      
      </div>

    </div>
   

  </div>
  <div className='col-12 '>
    <div className='row'>
    <div className='col-md-6 col-sm-6 col-xs-6 col-lg-6  text-center my-3'>
    <h4 className='card-heading'>Vegetable Samosa</h4>
    <img  className='img-fluid'Style={'border-radius: 15px' } src={card4}alt="card4"></img>

      </div>
      <div className='col-md-6 col-sm-6 col-xs-6 col-lg-6 text-center my-3'>
      <h4 className='card-heading'>Spring Rolls </h4>
      <img  className='img-fluid' Style={'border-radius: 15px' } src={card5} alt="card5"></img>
      
      </div>
      

    </div>
   

  </div>

   


</div>




  </div>;
};

export default Cards;


      



