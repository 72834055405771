import React from 'react';
import axios from "axios"
import sides from "../../Assests/10007.jpg"

class Join extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
          email: ''
         
        }
      };

      handleSubmit(e){
        e.preventDefault();
        axios({
          method: "POST",
          url:"http://mailus.tempurahalal.com/api/subcribers",
          data:  this.state
        }).then((response)=>{
          if (response.data.status == "200") {
            alert("Message Sent");
            this.resetForm()
          } else if (response.data.status == '400') {
            alert("Message failed to send.")
          }
        })
      }
      resetForm(){
        this.setState({ email: ''})
      }
   


 

    

render(){
  return <div className='container-fuild'>

      <div className='row' Style={'width :98% ; margin:auto'  }>

          <div className='col-md-6 col-sm-6 col-lg-6  col-xs-6 col-xl-6 col-xxl-6'  Style='padding :30px'>
              <div className='al'>

              <h3  className='join-heading'>
Join us for exclusive offers, updates, and more!</h3>

           <p Style={'color: darkgray;'}>
           Become part of our fast growing community and be the first to know about our latest dishes, get access to online-only offers, and so much more!
           </p>

           <h5 className='sign'  Style={'color:white'} >Sign up for our mailing group:</h5>
            <form   onSubmit={this.handleSubmit.bind(this)} method="POST" >
           <input type="email" Style={"color:white"} className='email-input' placeholder='Enter Email'  required value={this.state.email} onChange={this.onEmailChange.bind(this)} /> <br/>
           <button className='btn' type='submit'>Submit</button>
           </form>
           </div>

          </div>


          <div className='col-md-6 col-sm-6 col-lg-6  col-xs-6 col-xl-6 col-xxl-6' Style={'margin-bottom:20px'}>
              <img className='details-img' Style={'border-radius: 15px' } src={sides} alt="lasty"></img>

          </div>

      </div>

    




  </div>;
}

onEmailChange(event) {
    this.setState({email: event.target.value})
  }
};


export default Join;
