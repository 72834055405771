
import React,{useState} from 'react';
import axios from 'axios';
import lasty from "../../Assests/Reception-02.png"


class LastSection extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
          email: ''
         
        }
      };

      handleSubmit(e){
        e.preventDefault();
        axios({
          method: "POST",
          url:"http://mailus.tempurahalal.com/api/subcribers",
          data:  this.state
        }).then((response)=>{
          if (response.data.status == "200") {
            alert("Message Sent");
            this.resetForm()
          } else if (response.data.status == '302') {
            alert("Message failed to send.")
          }
        })
      }
      resetForm(){
        this.setState({ email: ''})
      }


render(){
    return (
    <>

       <div className="container-fluidu">
           <div className='col-12 '>
           <div className='row text-center'>
            <div className='col-md-3 col-sm-3 col-xl-3 col-lg-3'>
            <h6 className='lastheading'>Additional Links</h6>
            <a href='/' className='links'> Terms of Service</a><br />
            <a href='/' className='links'>Privacy Policy</a><br />
            <a href='/' className='links' > Trade Media</a>

            </div>
            <div className='col-md-3 col-sm-3 col-xl-3 col-lg-3'>
            <h6 >Questions or Comments?</h6>
            <p className='last2-heading'>Message us on our contact page, and we will get back to you as soon as possible.</p>

            <p className='last2-heading'>Colonel Kababz food products are manufactured by Kaiser Foodline, LLC at its Garland, Texas location.</p>

                

</div>
<div className='col-md-3 col-sm-3 col-xl-3 col-lg-3'>
<h6 className='ENJOY'>Enjoy exclusive offers and updates, by joining our mailing group.</h6>
<form method='POST'  onSubmit={this.handleSubmit.bind(this)}  >
<input className='lastinput'Style={"color:white"} placeholder='Enter Email'  type="email"value={this.state.email} onChange={this.onEmailChange.bind(this)}  /> <br/>
<button className='submit'  type='submit'>Submit</button>
</form>
</div>
<div className='col-md-3 col-sm-3 col-xl-3 col-lg-3'>
<img className='lastlogo' src={lasty} alt="last"  Style={"margin-top:-36px"}/>

<h6 className="headinglast">The Authentic Indo-Mediterranean Experience</h6>


</div>



           </div>
           


           </div>


       </div>
          


  
    
    </>
    )
      
};
onEmailChange(event) {
    this.setState({email: event.target.value})
  }
}

export default LastSection;
