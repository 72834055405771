import React from 'react';
import side from "../../Assests/10006.jpg"

const Details = () => {
    return <div className='container-fuild'>

        <div className='row' Style={'width :98% ; margin:auto'  }>


            <div className='col-md-6'>
                <img className='details-img' Style={'border-radius: 15px'} src={side} alt="side" />

            </div>
            <div className='col-md-6' Style='padding: 80px 0px 0px 130px;' >

                <h3 className='details-headings'>
                    Over 40 Years of Quality Cooking</h3>

                <h5 className='details-h2'  Style={'color:white'}>WE ALWAYS…</h5>
                <p className='details-p'  Style={'color: darkgray;'} >
                    ✓ Carefully Select Only Wholesome Ingredients <br />
                    ✓ Cook With Fresh Herbs & Spices  <br />
                    ✓ Use Meat That Is All Natural and USDA Certified Halal  <br />
                </p>
                <h5 className='details-headingss'  Style={'color:white'}>WE DON’T USE OR HAVE…</h5>
                <p className='details-p'  Style={'color: darkgray;'}>
                    ❌ Preservatives  <br />
                    ❌ GMOs  <br />
                    ❌ Added Antibiotics or Growth Hormones  <br />
                    ❌ Artificial Ingredients   <br />
                    ❌ Trans Fat   <br />
                </p>

            </div>

        </div>





    </div>;
};

export default Details;
