
import './App.css';

import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import back from "../src/Assests/10021.jpg"
import { Route, Routes } from "react-router-dom"
import Our from "../src/Components/OurStory/Our"
import OurFood from './Components/OurFood/OurFood';
import Contact from './Components/Contact/Contact';
import Navbar from './Components/Navbar/Header';
import Storelocatior from '../src/Components/StoreLocator/Storelocatior';


const App = () => {
  return (<> 



  
    <div className='Main' style={{ backgroundImage: `url(${back})` }}>
      <div className='section'>


   <Navbar/>
 <Routes>
   <Route path="/" element={<Home/>} />
   <Route path="/story" element={<Our/>} />
   <Route  path="/Food" element={<OurFood/>}/>
   <Route  path="/contact" element={<Contact/>}/>
     <Route path="/Storelocator"  element ={<Storelocatior/>}/>
        
        </Routes>
      
  <hr className='divider'/>

        <Footer />

      </div>
    </div>
  

  </>
  )
}

export default App;
