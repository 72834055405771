
import React,{useState} from 'react';
import axios from "axios"
import "../Contact/Contact.css"
class Contact extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      contact_no:'',
      message: ''
    }
  };

 
  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST",
      url:"http://mailus.tempurahalal.com/api/contact-us",
      data:  this.state
    }).then((response)=>{
      if (response.data.status == "200") {
        alert("Message Sent");
        this.resetForm()
      } else if (response.data.status == '400') {
        alert("Message failed to send.")
      }
    })
  }
  resetForm(){
    this.setState({name: '', email: '', message: '',contact_no: ''})
  }


  render() {
    return(
      <div className="container-fluid">
        <div className='too'>
        <h1 className='contact-heading' Style={' color: #971B1E;'}>Contact  US</h1> 
        <h4  className='contact-headings'Style={'color:white'}>Get in Touch With Us</h4> 
        <p className='contact-heading' Style={'color:white'}>Fill in the form below, and our team will get back to you as soon as possible.</p>
  
        <form id="contact" onSubmit={this.handleSubmit.bind(this)} method="POST"  className='contact'>
          <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" placeholder="Your name" className="form-control" id="name" value={this.state.name} required onChange={this.onNameChange.bind(this)} />
          </div>
          <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input type="email"  placeholder="Your Email Address" className="form-control" id="email" aria-describedby="emailHelp" required value={this.state.email} onChange={this.onEmailChange.bind(this)} />
          </div>
          <div className="form-group">
              <label htmlFor="exampleInputNumber">Phone </label>
              <input type="tel" className="form-control"   placeholder="Your Phone Number " id="tel" aria-describedby="emailHelp" required value={this.state.contact_no} onChange={this.onContact_no.bind(this)} />
          </div>
          <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" placeholder="Type your message here...." rows="5" id="message" value={this.state.message} required onChange={this.onMessageChange.bind(this)} />
          </div>
          <button type="submit"  id="contact-submit">Submit</button>
        </form>
      </div>
      </div>
    );
  }
  onNameChange(event) {
    this.setState({name: event.target.value})
  }
  onEmailChange(event) {
    this.setState({email: event.target.value})
  }
  onMessageChange(event) {
    this.setState({message: event.target.value})
  }
  
  onContact_no(event) {
    this.setState({contact_no: event.target.value})
  }
}
export default Contact;
