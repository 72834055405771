import React from 'react'

function PageBanner(props) {
    const banner = props.banner ? props.banner : 'assets/img/page-banner/SERVICE BANNER .jpg'
    return (
        <div>
            <img className="img-fluid w-100" src={banner} alt="" />
        </div>
    )
}

export default PageBanner